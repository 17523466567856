module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CMP Builders","short_name":"CMP Builders","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"/Users/illmonk/Documents/GitHub/gatsby-cmp-builders/src/images/favicon.png","icons":[{"src":"/Users/illmonk/Documents/GitHub/gatsby-cmp-builders/src/images/HammerIcon.png","sizes":"64x64 32x32 24x24 16x16","type":"image/png"},{"src":"/Users/illmonk/Documents/GitHub/gatsby-cmp-builders/src/images/logo192.png","sizes":"192x192","type":"image/png"},{"src":"/Users/illmonk/Documents/GitHub/gatsby-cmp-builders/src/images/logo512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"41c21c254cb5a63f5258cbedb6e65203"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
