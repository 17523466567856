exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-building-js": () => import("./../../../src/pages/custom-building.js" /* webpackChunkName: "component---src-pages-custom-building-js" */),
  "component---src-pages-estimate-js": () => import("./../../../src/pages/estimate.js" /* webpackChunkName: "component---src-pages-estimate-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plumbing-js": () => import("./../../../src/pages/plumbing.js" /* webpackChunkName: "component---src-pages-plumbing-js" */)
}

